import { QueryResult, Address, BankAccount, LoanStatus, LoanInfoDto, PaymentFrequency, AccountActivationStage, AccountModificationFlag, FeeType, PromotionInfo } from 'common';
import { AppBarExpandedTabWithPermission } from '../app.data';
import { readOnly } from '../user/user-permission/user-permission.data';
import { Note, NoteData } from '../shared/note/note.model';

export interface AccountBalanceData {
  balanceOutstanding?: number;
  fundsAvailable?: number;
  paymentDue?: number;
  paymentDueDate?: any;
}


export interface AccountData {
  active?: boolean;
  activationStage?: AccountActivationStage;
  activationStageLog?: any;
  advisorId?: number;
  advisorName?: string;
  advisorEmail?: string;
  advisorPhone?: string;
  advisorAvatar?: string;
  autoPayMode?: number;
  id?: number;
  loanNumber?: string;
  uniqueId?: string;
  applicationNumber?: string;
  applicationUniqueId?: string;
  entityId?: number;
  entityNumber?: string;
  entityName?: string;
  entityDBA?: string;
  entityEIN?: string;
  entityAddress?: Address;
  entityPhone?: string;
  currentState?: AccountStateDto;
  cutoffState?: AccountStateDto;
  maintenanceState?: AccountStateDto;
  owners?: CustomerAccountData[];
  offer?: AccountOfferData;
  creditLimit?: number;
  balance?: number;
  principal?: number;
  accruedInterest?: number;
  paymentAmount?: number;
  createdOn?: any;
  updatedOn?: Date;
  openedOn?: any;
  renewalDate?: any;
  renewedBy?: string;
  renewalFor?: string;
  delinquentStatus?: DelinquentStatus;
  status?: number;
  frozenUntil?: Date;
  freezeForDays?: number;
  noAutoPayments: boolean;
  noInterestAccrual: boolean;
  lastMaintenanceDate?: Date;
  lastReAmortizationDate?: Date;
  fullAmortizationStartDateUtc?: Date;
  productId?: number;
  productCode?: ProductCode;
  productName?: string;
  disbursementBankAccountData?: BankAccount;
  paymentBankAccountData?: BankAccount;
  externalId?: string;
  description?: string;
  policyNumber?: string;
  policyLimit?: number;
  modification?: AccountModification;
  modificationFlag?: AccountModificationFlag;
  modificationHistory?: AccountModificationSnapshot[];
  repaymentTermOverride?: number;
  allowDraw?: boolean;
  allowPayment?: boolean;
  notes?: Note[];
  drawDownFee?: number;
  drawDownFeeType?: FeeType;
  repaymentTerm?: number;
  interestRate?: number;
  maintenanceFee?: number;
  maintenanceFeeType?: number;
  activationDate?: Date;
  stageChangeDate?: Date;
  maturityDate?: Date;
  loanInfo?: LoanInfoDto;
  alligatorMatch?: boolean;
  statementGeneration?: AccountStatementsRequestData;
}

export interface AccountWithRenewalData extends AccountData {
  renewalForLoanNumber?: string;
  renewalForProductName?: string;
  renewedByLoanNumber?: string;
  renewedByProductName?: string;
}

export enum ProductCode {
  'Line of Credit' = 'LOC',
  'Term Loan' = 'Term',
  'Case Financing' = 'Law'
}

export interface CustomerAccountData
{
  customerId: number,
  favorite: boolean
}

export interface LoanStatusUpdateDto {
  status?: LoanStatus;
  delinquentStatus?: DelinquentStatus;
  freezeForDays?: number;
  fullAmortizationStartDate?: Date;
}

export enum DelinquentStatus {
  Current = 'current',
  Delinquent = 'delinquent',
  Delinquent7Days = 'delinquent-7-days',
  Delinquent14Days = 'delinquent-14-days',
  Delinquent30Days = 'delinquent-30-days'
}

export const DelinquentStatusLabel = new Map<string, string>([
  [DelinquentStatus.Current, 'Current'],
  [DelinquentStatus.Delinquent, 'Delinquent'],
  [DelinquentStatus.Delinquent7Days, 'Delinquent 7 days'],
  [DelinquentStatus.Delinquent14Days, 'Delinquent 14 days'],
  [DelinquentStatus.Delinquent30Days, 'Delinquent 30 days']
]);

export interface AccountAmortizationData {
  date?: Date;
  type?: string;
  amount?: number;
  paymentNumber?: number;
  principalAmount?: number;
  interestAmount?: number;
  balance?: number;
  totalPrincipal?: number;
  totalInterest?: number;
  discountAvailable?: number;
  payoffAmount?: number;
}

export interface AccountStateWidgetData {
  stateData?: AccountStateDto;
  availableFunds?: number;
  lastMaintenanceDate?: Date;
  utilization?: number;
}

export interface AccountOfferData {
  simpleInterestRate?: number;
  dailyInterestRate?: number;
  monthlyInterestRate?: number;
  weeklyInterestRate?: number;
  repaymentTerm?: number;
  drawTerm?: number;
  interestRate?: number;
  amount?: number;
  paymentFrequency?: PaymentFrequency;
  drawDownFee?: number;
  drawDownFeeType?: FeeType;
  maintenanceFee?: number;
  maintenanceFeeType?: FeeType;
  maintenanceFeeFrequency?: number;
  noPaymentTerms?: number;
  interestOnlyPaymentTerms?: number;
  originationFee?: number;
  originationFeeType?: FeeType;
  uccFilingFee?: number;
  uccFilingFeeType?: FeeType;
  servicingFee?: number;
  servicingFeeType?: FeeType;
  servicingFeeMinimum?: number;
  servicingFeeMinimumType?: FeeType;
  stackingFee?: number;
  stackingFeeType?: FeeType;
}

export interface LoanOtherInfoUpdateDto {
  externalId: string;
  description: string;
  policyNumber: string;
  policyLimit: number;
}

export interface AccountQueryResult extends QueryResult<AccountData> { }

export interface AccountStateDto {
  principalBalance?: number;
  totalFundedAmount?: number;
  totalFees?: number;
  accruedInterest?: number;
  paymentAmount?: number;
  paymentDueDate?: any;
  availableBalance?: number;
  cycleStartDate?: any;
  cycleStartDay?: string;
  firstDrawAmount?: number;
  firstDrawDate?: any;
  lastDrawAmount?: number;
  lastDrawDate?: any;
  lastManualPaymentAmount?: number;
  lastManualPaymentDate?: any;
  lastAutoPaymentAmount?: number;
  lastAutoPaymentDate?: any;
  feeBalance?: number;
  utilization?: number;
  creditLimit?: number;
  totalInterestCharged?: number;
  totalInterestPaid: number;
  totalPrincipalPaid: number;
}

export interface AccountStateUpdateDto {
  principalBalance: number;
  feeBalance: number;
  totalFundedAmount: number;
  totalFees: number;
  accruedInterest: number;
  totalInterestCharged: number;
  paymentAmount: number;
  cycleStartDate: Date;
}

export interface AccountCustomerInfoData {
  id: number;
  businessName: string;
  loanNumber: string;
  status: string;
}

export interface AccountAmortizationInfo {
  date?: Date;
  type?: string;
  amount?: number;
  paymentNumber?: number;
  principalAmount?: number;
  interestAmount?: number;
  balance?: number;
  interestBalance?: number;
}

export interface AccountModification {
  id?: number;
  name?: string;
  startDate?: Date;
  endDate?: Date;
  active?: boolean;
  activatedOn?: Date;
  createdOn?: Date;
  createdBy?: any;
  updatedBy?: any;
  updatedOn?: Date;
  notes?: NoteData[];
  updatedNotes?: string[];
  autoPaymentMode?: AutoPaymentMode;
  fixedPaymentAmount?: number;
  accrueInterest?: boolean;
  interestRate?: number;
  drawFee?: number;
  drawFeeType?: FeeType;
  maintenanceFee?: number;
  maintenanceFeeType?: FeeType;
  originalPaymentAmount?: number;
  originalRepaymentTerm?: number;
}

export interface TransactionPreviewResponse
{
  disbursedAmount?: number;
  fee?: number;
  principalAmount?: number;
  interestAmount?: number;
  newOutstandingBalance?: number;
  newAvailableFunds?: number;
  newUpcomingPaymentAmount?: number;
  newUpcomingPaymentDate?: Date;
  promotionInfo?: PromotionInfo;
}

export interface AccountModificationSnapshot extends AccountModification {
  reAmortized?: boolean;
  loanStatusSnapshot?: LoanStatus;
  repaymentTerm?: number;
  principalBalance?: number;
  paymentAmount?: number;
}

export interface AccountModificationQueryResult extends QueryResult<AccountModificationSnapshot | any> { }

export interface AccountModificationPreview {
  amortizationInfo?: AccountAmortizationInfo[];
  newPaymentDue?: number;
  newPaymentDueDate?: Date;
}

export interface AccountModificationRequestDto {
  reAmortize?: boolean;
  status?: LoanStatus;
  freezeForDays?: number;
  repaymentTerm?: number;
  modification?: AccountModification;
}

export interface AccountStatementsData extends AccountStatementsRequestData {
  loanId?: number;
  alligatorMatch?: boolean;
}

export interface AccountStatementsRequestData {
  generateStatements?: boolean;
  generateStatementsExpirationDate?: Date;
}

export enum AutoPaymentMode {
  None = 0,
  Normal = 1,
  InterestOnly = 2,
  FixedAmount = 3
}

export const AutoPaymentModeMap = new Map<number, string>([
  [AutoPaymentMode.None, 'No auto-payment'],
  [AutoPaymentMode.Normal, 'Normal'],
  [AutoPaymentMode.InterestOnly, 'Interest only'],
  [AutoPaymentMode.FixedAmount, 'Fixed amount']
]);



export enum AccountModificationFlagFilter {
  None = '0',
  CurrentModification = '1',
  PastModification = '2',
  FutureModification = '3'
}

export const AccountModificationFlagFilterMap = new Map<string, string>([
  [AccountModificationFlagFilter.None, 'No modification'],
  [AccountModificationFlagFilter.CurrentModification, 'Current modification'],
  [AccountModificationFlagFilter.PastModification, 'Past modification'],
  [AccountModificationFlagFilter.FutureModification, 'Future modification']
]);

export enum AccountOfferPaymentFrequencies {
  'Weekly' = 0,
  'Bi weekly' = 1,
  'Monthly' = 2
}

export interface AccountOnboarding {
  pushed?: number;
  registered?: number;
  verified?: number;
  signed?: number;
  activated?: number;
}

export interface AccountBreakdown {
  pending?: number;
  open?: number;
  closed?: number;
  frozen?: number;
  pendingRenewal?: number;
  pendingClosure?: number;
  declined?: number;
  renewalDeclined?: number;
  delinquent?: number;
  paymentRelief?: number;
  paymentDeferral?: number;
  workout?: number;
  writeOff?: number;
}

export interface AccountAverage {
  product?: string;
  balance?: number;
  amount?: number;
  utilization?: number;
}

export enum LookupType {
  ApplicationNumber = 0,
  BusinessName = 1,
  LoanNumber = 2,
  Email = 3
}



export const accountAppBarExpandedTabs = (accountId: number): AppBarExpandedTabWithPermission[] => [
  {
    label: 'Profile',
    url: `/account/${accountId}/profile`,
    permission: [readOnly('servicing-accounts')],
    hidden: true
  },
  {
    label: 'Economics',
    url: `/account/${accountId}/economics`,
    permission: [readOnly('servicing-accounts')],
    hidden: true
  },
  {
    label: 'Transactions',
    url: `/account/${accountId}/transactions`,
    permission: [readOnly('servicing-transactions')],
    loanStatusNotAllowed: [LoanStatus.Delinquent, LoanStatus.Workout, LoanStatus.PaymentRelief, LoanStatus.WriteOff],
    hidden: true
  },
  {
    label: 'Documents',
    url: `/account/${accountId}/documents`,
    permission: [readOnly('servicing-account-documents')],
    hidden: true
  },
  {
    label: 'Bank accounts',
    url: `/account/${accountId}/bank-account`,
    permission: [readOnly('servicing-bank-accounts')],
    hidden: true
  },
  {
    label: 'Bank analysis',
    url: `/account/${accountId}/bank-analysis`,
    permission: [readOnly('servicing-bank-analysis'), readOnly('servicing-entities-sensitive-data')],
    allPermissionsRequired: true,
    hidden: true
  },
  {
    label: 'Modifications',
    url: `/account/${accountId}/modification`,
    permission: [readOnly('servicing-account-modification')],
    hidden: true
  },
  {
    label: 'Audit Trail',
    url: `/account/${accountId}/audit`,
    permission: [readOnly('admin-audit-log')],
    hidden: true
  }
];

export const accountSFAppBarExpandedTabs = (uniqueId: string): AppBarExpandedTabWithPermission[] => [
  {
    label: 'Profile',
    url: `/account/sf/${uniqueId}/profile`,
    permission: [readOnly('servicing-accounts')],
    hidden: true
  },
  {
    label: 'Economics',
    url: `/account/sf/${uniqueId}/economics`,
    permission: [readOnly('servicing-transactions')],
    hidden: true
  },
  {
    label: 'Transactions',
    url: `/account/sf/${uniqueId}/transactions`,
    permission: [readOnly('servicing-transactions')],
    hidden: true
  },
  {
    label: 'Bank accounts',
    url: `/account/sf/${uniqueId}/bank-account`,
    permission: [readOnly('servicing-bank-accounts')],
    hidden: true
  },
  {
    label: 'Bank analysis',
    url: `/account/sf/${uniqueId}/bank-analysis`,
    permission: [readOnly('servicing-bank-analysis'), readOnly('servicing-entities-sensitive-data')],
    allPermissionsRequired: true,
    hidden: true
  },
  {
    label: 'Modifications',
    url: `/account/sf/${uniqueId}/modification`,
    permission: [readOnly('servicing-account-modification')],
    hidden: true
  },
  {
    label: 'Audit Trail',
    url: `/account/sf/${uniqueId}/audit`,
    permission: [readOnly('admin-audit-log')],
    hidden: true
  }
];
