import { AccountData, AccountAmortizationData } from '../account/account.model';
import { UserPermissionData, readWrite } from '../user/user-permission/user-permission.data';
import { FailureReasonBaseData } from '../admin/failure-reason/failure-reason.model';
import {
  QueryParams,
  QueryResult,
  LoanInfoDto,
  InitiatorType,
  TransactionType,
  TransactionStatus,
  SubtransactionData,
  parseNumber,
  parseNumberArray,
  LiquidityEvent,
  Address
} from 'common';
import { Note } from '../shared/note/note.model';
import { UserData } from '../user/user.model';
import { AdvisorData } from '../advisor/advisor.model';
import { PromotionSelectOptionData } from '../admin/promotion/models/promotion.model';
import {GeographicalPosition} from "common/lib/documents/signed-loan-document-file.model";

export class TransactionData {
    id?: number;
    entityId?: number;
    accountId?: number;
    loanNumber?: string;
    businessName?: string;
    date?: Date;
    processDate?: Date;
    underwritingDate?: Date;
    financeDate?: Date;
    completeDate?: Date;
    cancellationDate?: Date;
    amount?: number;
    displayAmount?: number;
    initiator?: string;
    initiatorType?: InitiatorType;
    initiatorId?: number;
    modifier?: string;
    creator?: string;
    advisor?: string;
    type?: TransactionType;
    status?: TransactionStatus;
    maintained?: string;
    disbursedAmount?: number;
    thirdPartyPayoffAmount?: number;
    deferredFeeAmount?: number;
    cashFeeAmount?: number;
    principalAmount?: number;
    interestAmount?: number;
    notes?: Note[];
    failureReasons?: string[];
    subtransactions?: SubtransactionData[];
    bankAccountId?: number;
    isScreenShareRequired?: boolean;
    isAbleToUnderwrite?: boolean;
    isTransferBankAccountLinked?: boolean;
    missingInformation?: string[];
    wasScreenShareRequiredAtUWTime?: boolean;
    wasAbleToUnderwriteAtUWTime?: boolean;
    wasTransferBankAccountLinkedAtUWTime?: boolean;
    transferAmount?: number;
    isFirstDraw?: boolean;
    loanModificationSnapshotId?: number;
    closingBalance?: number;
    outstandingInterest?: number;
    outstandingPrincipal?: number;
    creditLimit?: number;
    utilization?: number;
    totalPrincipal?: number;
    totalInterest?: number;
    totalDeferredFees?: number;
    totalCashFees?: number;
    totalFees?: number;
    totalPayback?: number;
    promotions?: PromotionSelectOptionData[];
    competitors?: number[];
    loanPrincipalBalance?: number;
    loanCreditLimit?: number;
    drawRequirements?: DrawRequirementData[];
    customerDrawGeographicalPosition?: GeographicalPosition;
    businessAddress?: Address;
}

export const CanUserEditTransactionInStatus: UserPermissionData[] = [];

export const CanUserMoveTransactionToStatus: UserPermissionData[] = [];

function UpdateCanUserEditTransactionInStatusArray(status: TransactionStatus, permission: UserPermissionData) {
    CanUserEditTransactionInStatus[status] = permission;
}

function UpdateCanUserMoveTransactionToStatusArray(status: TransactionStatus, permission: UserPermissionData) {
    CanUserMoveTransactionToStatus[status] = permission;
}

UpdateCanUserEditTransactionInStatusArray(TransactionStatus.Pending, readWrite('servicing-editing-pending-transactions'));
UpdateCanUserEditTransactionInStatusArray(TransactionStatus.Processed, readWrite('servicing-editing-processed-transactions'));
UpdateCanUserEditTransactionInStatusArray(TransactionStatus.Rejected, readWrite('servicing-editing-underwritten-transactions'));
UpdateCanUserEditTransactionInStatusArray(TransactionStatus.Cancelled, readWrite('servicing-editing-processed-transactions'));
UpdateCanUserEditTransactionInStatusArray(TransactionStatus.Approved, readWrite('servicing-editing-underwritten-transactions'));
UpdateCanUserEditTransactionInStatusArray(TransactionStatus.Verified, readWrite('servicing-editing-underwritten-transactions'));
UpdateCanUserEditTransactionInStatusArray(TransactionStatus.Complete, readWrite('servicing-editing-processed-transactions'));
UpdateCanUserEditTransactionInStatusArray(TransactionStatus.Declined, readWrite('servicing-editing-processed-transactions'));
UpdateCanUserEditTransactionInStatusArray(TransactionStatus.Abandoned, readWrite('servicing-editing-processed-transactions'));
UpdateCanUserEditTransactionInStatusArray(TransactionStatus.OnHold, readWrite('servicing-editing-underwritten-transactions'));

UpdateCanUserMoveTransactionToStatusArray(TransactionStatus.Pending, readWrite('servicing-moving-transactions-to-pending'));
UpdateCanUserMoveTransactionToStatusArray(TransactionStatus.Processed, readWrite('servicing-processing-transactions'));
UpdateCanUserMoveTransactionToStatusArray(TransactionStatus.Rejected, readWrite('servicing-underwriting-transactions'));
UpdateCanUserMoveTransactionToStatusArray(TransactionStatus.Cancelled, readWrite('servicing-cancelling-transactions'));
UpdateCanUserMoveTransactionToStatusArray(TransactionStatus.Approved, readWrite('servicing-underwriting-transactions'));
UpdateCanUserMoveTransactionToStatusArray(TransactionStatus.Verified, readWrite('servicing-processing-transactions'));
UpdateCanUserMoveTransactionToStatusArray(TransactionStatus.Complete, readWrite('servicing-processing-transactions'));
UpdateCanUserMoveTransactionToStatusArray(TransactionStatus.Declined, readWrite('servicing-processing-transactions'));
UpdateCanUserMoveTransactionToStatusArray(TransactionStatus.Abandoned, readWrite('servicing-processing-transactions'));
UpdateCanUserMoveTransactionToStatusArray(TransactionStatus.OnHold, readWrite('servicing-underwriting-transactions'));

export class TransactionQueryParams extends QueryParams {
    loanId?: number;
    renewalFor?: string;
    loanNumber?: string;
    uniqueId?: string;
    businessName?: string;
    type?: TransactionType[];
    id?: number;
    status?: TransactionStatus[];
    loanCreatedFrom?: any;
    loanCreatedTo?: any;
    companyId?: number;

    amountFrom?: number;
    amountTo?: number;

    creditLimitFrom?: number;
    creditLimitTo?: number;

    promotionIds?: number[];

    fromDate?: any;
    toDate?: any;
    transactionDate?: string;

    processDateFrom?: any;
    processDateTo?: any;
    processDate?: string;

    principalAmount?: string;
    principalAmountFrom?: number;
    principalAmountTo?: number;
    principalAmountExcept?: number;

    interestAmount?: string;
    interestAmountFrom?: number;
    interestAmountTo?: number;
    interestAmountExcept?: number;

    deferredFeeAmount?: string;
    deferredFeeAmountFrom?: number;
    deferredFeeAmountTo?: number;
    deferredFeeAmountExcept?: number;

    cashFeeAmount?: string;
    cashFeeAmountFrom?: number;
    cashFeeAmountTo?: number;
    cashFeeAmountExcept?: number;

    disbursedAmount?: string;
    disbursedAmountFrom?: number;
    disbursedAmountTo?: number;
    disbursedAmountExcept?: number;

    constructor(query?: any) {
      super(query)
      query = query || {};
      this.loanId = parseNumber(query.loanId) || undefined;
      this.renewalFor = query.renewalFor || undefined;
      this.loanNumber = query.loanNumber || undefined;
      this.uniqueId = query.uniqueId || undefined;
      this.businessName = query.businessName || undefined;
      this.type = query.type || undefined;
      this.id = parseNumber(query.id) || undefined;
      this.status = query.status || undefined;
      this.loanCreatedFrom = query.loanCreatedFrom || undefined;
      this.loanCreatedTo = query.loanCreatedTo || undefined;
      this.companyId = parseNumber(query.companyId) || undefined;

      this.amountFrom = query.amountFrom || undefined;
      this.amountTo = query.amountTo || undefined;

      this.creditLimitFrom = query.creditLimitFrom || undefined;
      this.creditLimitTo = query.creditLimitTo || undefined;

      this.promotionIds = parseNumberArray(query.promotionIds) || undefined;

      this.fromDate = query.fromDate || undefined;
      this.toDate = query.toDate || undefined;
      this.transactionDate = query.transactionDate || undefined;

      this.processDateFrom = query.processDateFrom || undefined;
      this.processDateTo = query.processDateTo || undefined;
      this.processDate = query.processDate || undefined;

      this.principalAmountFrom = parseNumber(query.principalAmountFrom);
      this.principalAmountTo = parseNumber(query.principalAmountTo);
      this.principalAmountExcept = parseNumber(query.principalAmountExcept);
      this.principalAmount = query.principalAmount || undefined;

      this.interestAmountFrom = parseNumber(query.interestAmountFrom);
      this.interestAmountTo = parseNumber(query.interestAmountTo);
      this.interestAmountExcept = parseNumber(query.interestAmountExcept);
      this.interestAmount = query.interestAmount || undefined;

      this.deferredFeeAmountFrom = parseNumber(query.deferredFeeAmountFrom);
      this.deferredFeeAmountTo = parseNumber(query.deferredFeeAmountTo);
      this.deferredFeeAmountExcept = parseNumber(query.deferredFeeAmountExcept);
      this.deferredFeeAmount = query.deferredFeeAmount || undefined;

      this.cashFeeAmountFrom = parseNumber(query.cashFeeAmountFrom);
      this.cashFeeAmountTo = parseNumber(query.cashFeeAmountTo);
      this.cashFeeAmountExcept = parseNumber(query.cashFeeAmountExcept);
      this.cashFeeAmount = query.cashFeeAmount || undefined;

      this.disbursedAmountFrom = parseNumber(query.disbursedAmountFrom);
      this.disbursedAmountTo = parseNumber(query.disbursedAmountTo);
      this.disbursedAmountExcept = parseNumber(query.disbursedAmountExcept);
      this.disbursedAmount = query.disbursedAmount || undefined;
    }
}

export interface TransactionQueryResult extends QueryResult<TransactionData> {
}

export interface TransactionActionCommand {
    id?: number;
    status?: number;
    type?: TransactionType;
    notes?: string[];
    failureReasons?: string[];
    loanId?: number;
    date?: Date;
    processDate?: Date;
    underwritingDate?: Date;
    financeDate?: Date;
    completeDate?: Date;
    cancellationDate?: Date;
    amount?: number;
    subtransactions?: SubtransactionData[];
    initiatorType?: InitiatorType,
    initiatorId?: number
    promotions?: number[];
    competitors?: number[];
}


export const TransactionStatusChips = {
    '0': { color: 'mat-grey-bg', icon: 'help', label: 'Pending' },
    '2': { color: 'mat-red-bg', icon: 'block', label: 'Rejected' },
    '3': { color: 'mat-orange-bg', icon: 'highlight_off', label: 'Cancelled' },
    '4': { color: 'mat-green-bg', icon: 'check_circle', label: 'Approved' },
    '5': { color: 'mat-indigo-bg', icon: 'done_all', label: 'Verified' },
    '7': { color: 'mat-purple-bg', icon: 'warning', label: 'Declined' },
    '8': { color: 'mat-red-bg', icon: 'sentiment_very_dissatisfied', label: 'Abandoned' },
    '9': { color: 'mat-indigo-bg', icon: 'help', label: 'On Hold' }
};

export class TransactionFilters {
    loanId: number;
    loanNumber: any;
    businessName: any;
    type: TransactionType[];
    id: number;
    status: TransactionStatus[];
    companyId: number;

    amountFrom: number;
    amountTo: number;

    creditLimitFrom: number;
    creditLimitTo: number;

    promotionIds: number[];

    fromDate: Date;
    toDate: Date;
    transactionDate: string;

    processDateFrom: Date;
    processDateTo: Date;
    processDate: string;

    principalAmountFrom?: number;
    principalAmountTo?: number;
    principalAmountExcept?: number;
    principalAmount?: string;

    interestAmountFrom?: number;
    interestAmountTo?: number;
    interestAmountExcept?: number;
    interestAmount?: string;

    deferredFeeAmountFrom?: number;
    deferredFeeAmountTo?: number;
    deferredFeeAmountExcept?: number;
    deferredFeeAmount?: string;

    cashFeeAmountFrom?: number;
    cashFeeAmountTo?: number;
    cashFeeAmountExcept?: number;
    cashFeeAmount?: string;

    disbursedAmountFrom?: number;
    disbursedAmountTo?: number;
    disbursedAmountExcept?: number;
    disbursedAmount?: string;

    constructor(query?: TransactionQueryParams) {
        query = query || {};
        this.loanId = query.loanId || undefined;
        this.loanNumber = query.loanNumber || undefined;
        this.businessName = query.businessName || undefined;
        this.id = query.id || undefined;
        this.companyId = query.companyId;
        this.type = query.type;
        this.status = query.status;

        this.amountFrom = query.amountFrom || undefined;
        this.amountTo = query.amountTo || undefined;

        this.creditLimitFrom = query.creditLimitFrom || undefined;
        this.creditLimitTo = query.creditLimitTo || undefined;

        this.promotionIds = query.promotionIds

        this.fromDate = query.fromDate || undefined;
        this.toDate = query.toDate || undefined;
        this.transactionDate = query.transactionDate || undefined;

        this.processDateFrom = query.processDateFrom || undefined;
        this.processDateTo = query.processDateTo || undefined;
        this.processDate = query.processDate || undefined;


        this.principalAmountFrom = query.principalAmountFrom;
        this.principalAmountTo = query.principalAmountTo;
        this.principalAmountExcept = query.principalAmountExcept;
        this.principalAmount = query.principalAmount || undefined;

        this.interestAmountFrom = query.interestAmountFrom;
        this.interestAmountTo = query.interestAmountTo;
        this.interestAmountExcept = query.interestAmountExcept;
        this.interestAmount = query.interestAmount || undefined;

        this.deferredFeeAmountFrom = query.deferredFeeAmountFrom;
        this.deferredFeeAmountTo = query.deferredFeeAmountTo;
        this.deferredFeeAmountExcept = query.deferredFeeAmountExcept;
        this.deferredFeeAmount = query.deferredFeeAmount || undefined;

        this.cashFeeAmountFrom = query.cashFeeAmountFrom;
        this.cashFeeAmountTo = query.cashFeeAmountTo;
        this.cashFeeAmountExcept = query.cashFeeAmountExcept;
        this.cashFeeAmount = query.cashFeeAmount || undefined;

        this.disbursedAmountFrom = query.disbursedAmountFrom;
        this.disbursedAmountTo = query.disbursedAmountTo;
        this.disbursedAmountExcept = query.disbursedAmountExcept;
        this.disbursedAmount = query.disbursedAmount || undefined;
    }

    remove(item: string) {
        this[item] = undefined;
        if (item === 'fromDate') {
            this.toDate = undefined;
            this.transactionDate = undefined;
        }
        if (item === 'processDateFrom') {
            this.processDateTo = undefined;
            this.processDate = undefined;
        }


        if (item === 'principalAmountFrom' || item === 'principalAmountTo' || item === 'principalAmountExcept') {
            this.principalAmount = undefined;
        }
        if (item === 'interestAmountFrom' || item === 'interestAmountTo' || item === 'interestAmountExcept') {
            this.interestAmount = undefined;
        }
        if (item === 'deferredFeeAmountFrom' || item === 'deferredFeeAmountTo' || item === 'deferredFeeAmountExcept') {
            this.deferredFeeAmount = undefined;
        }
        if (item === 'cashFeeAmountFrom' || item === 'cashFeeAmountTo' || item === 'cashFeeAmountExcept') {
            this.cashFeeAmount = undefined;
        }
        if (item === 'disbursedAmountFrom' || item === 'disbursedAmountTo' || item === 'disbursedAmountExcept') {
            this.disbursedAmount = undefined;
        }
    }
}

export interface TransactionListComponentData {
    transactionQueryResult: TransactionQueryResult;
    account: AccountData;
    accountInfo: LoanInfoDto;
    failureReasons: FailureReasonBaseData[];
    accountAmortizationInfo: AccountAmortizationData[];
    userCurrent?: UserData;
    promotionsToSelect?: PromotionSelectOptionData[];
    liquidityEvent: LiquidityEvent;
    accountTransactionsList: boolean;
}

export interface TransactionsGroupedByAdvisor {
    advisor?: AdvisorData;
    totalAmount?: number;
    totalCount?: number;
}

export interface DrawRequirementData {
    type: DrawRequirementType,
    requirementCompleted: boolean,
    requirementCompletedOnUtc: Date,
    required: boolean,
}

export enum DrawRequirementType {
    TwoUniquePhoneNumbers = "two-unique-phone-numbers",
    UCCFilingCompleted = "ucc-filing-completed",
    WelcomeCallCompleted = "welcome-call-completed",
    RequestorLocationMatchesBusinessAddress = "requestor-location-matches-business-address",
}

export const DrawRequirementTypeLabel = new Map<string, (data: any) => string>([
  [DrawRequirementType.TwoUniquePhoneNumbers, () => "Unique phone numbers"],
  [DrawRequirementType.UCCFilingCompleted, () => "UCC Filing"],
  [DrawRequirementType.WelcomeCallCompleted, () => "Welcome call"],
  [DrawRequirementType.RequestorLocationMatchesBusinessAddress, (data: {
    requestorState?: string;
    businessState: string
  }) => `Requestor's location ${data.requestorState ? `(${data.requestorState}) ` : ""}differs from business address (${data.businessState})`]
]);
