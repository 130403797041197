import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AccountAmortizationData } from '../account.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AccountPaymentListComponentData } from './account-payment-list.model';
import { ProductCode } from 'common';

@Component({
  selector: 'ifb-account-payment-list',
  templateUrl: './account-payment-list.component.html',
  styleUrls: ['./account-payment-list.component.css']
})
export class AccountPaymentListComponent implements OnInit, OnChanges {

  dataSource = new MatTableDataSource<AccountAmortizationData>();
  tableColumns: string[] = [];

  @Input() data: AccountPaymentListComponentData;
  @Input() paginator: MatPaginator;

  ngOnInit() {
    this.initData(this.data, this.paginator);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initData(this.data, this.paginator);
  }

  private initData(data: AccountPaymentListComponentData, paginator: MatPaginator) {
    if(!data) return;
    this.dataSource.data = data.amortizationData;
    this.dataSource.paginator = paginator;
    this.setTableDetails(data.productCode);
  }

  private setTableDetails(productCode: ProductCode) {
      const baseColumns = [
        'paymentNumber',
        'date',
        'type',
        'amount',
        'principalAmount',
        'interestAmount',
        'balance',
        'interestBalance',
        'totalInterest',
        'totalPrincipal',
        'totalPayback'
      ];
      if (productCode === ProductCode.Term) {
        this.tableColumns = [...baseColumns, 'discount', 'payoff'];
      } else {
        this.tableColumns = baseColumns;
      }
  }

 
}
