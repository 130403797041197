<ifb-account-notes-sidenav [accountId]="data.account?.id" [notes]="data.account?.notes" [initiator]="data.userCurrent"
  [saveOnDemand]="true" [innerSidenav]="true">
  <ifc-app-scroll-container [widgetsWithTopHeader]="true">
    <div class="layout margin flex-std" [@routerTransition]="'fly'">
      <ifb-panel>
        <ifb-card>
          <div *ngIf="canReadAccountStatus">
            <ifb-account-profile-status-widget [data]="data" [editStatus]="canEditAccountStatus" [editInfo]="canEditAccountInfo">
            </ifb-account-profile-status-widget>
          </div>
          <div class="show-widget-s">
            <ifb-owners-widget [owners]="data.owners" [saleforceViewMode]="saleforceViewMode"></ifb-owners-widget>
          </div>
        </ifb-card>

        <ifb-card>
          <ifb-account-liquidity-event-widget *ngIf="data.account?.productCode === productCodeOptions.Law && data.liquidityEvent"
            [data]="data.liquidityEvent"
            [loanId]="data.account?.id"
            (updated)="refreshAccountData()"></ifb-account-liquidity-event-widget>

          <ifb-account-profile-company-info-widget [data]="data"></ifb-account-profile-company-info-widget>

          <ifb-account-statements-widget [data]="data.accountStatements" [canEditAccountDocumentsGeneration]="canEditDocumentGeneration" [canViewAccountDocumentsGeneration]="canViewDocumentGeneration">
          </ifb-account-statements-widget>

          <ifb-account-profile-other-info-widget [data]="data" [edit]="canEditAccountOtherInfo">
          </ifb-account-profile-other-info-widget>

          <div *ngIf="canViewAdvisor" class="show-widget-s">
            <ifb-business-advisor-widget [data]="data.account" [canEditAdvisor]="canEditAdvisor"
              (edit)="changeAdvisor()">
            </ifb-business-advisor-widget>
          </div>
        </ifb-card>

        <ifb-card>
          <div class="show-widget-xl">
            <ifb-owners-widget [owners]="data.owners" [saleforceViewMode]="saleforceViewMode"></ifb-owners-widget>
          </div>
          <div *ngIf="canViewAdvisor" class="show-widget-xl">
            <ifb-business-advisor-widget [data]="data.account" [canEditAdvisor]="canEditAdvisor"
              (edit)="changeAdvisor()">
            </ifb-business-advisor-widget>
          </div>
        </ifb-card>
      </ifb-panel>
    </div>
  </ifc-app-scroll-container>
</ifb-account-notes-sidenav>
