import { AfterContentInit, Component, OnDestroy, OnInit, ViewChild, } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { animate, state, style, transition, trigger, } from "@angular/animations";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTabGroup } from "@angular/material/tabs";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  FilterParameter,
  LoanStatus,
  parseNumber,
  TransactionStatus,
  TransactionType,
  TransactionTypeLabel,
  TransactionTypeNames
} from "common";
import { TransactionService } from "../transaction.service";
import {
  TransactionListFilterDialogComponent
} from "../transaction-list-filter-dialog/transaction-list-filter-dialog.component";
import { TransactionFilterService } from "../transaction-filter.service";
import { AccountService } from "../../account/account.service";
import { UserPermissionItem, UserPermissionService, } from "../../user/user-permission/user-permission.service";
import { readOnly, readWrite, } from "../../user/user-permission/user-permission.data";
import { routerTransition } from "../../shared/animations/router.animations";
import { AccountData } from "../../account/account.model";
import {
  CanUserEditTransactionInStatus,
  CanUserMoveTransactionToStatus,
  DrawRequirementType,
  TransactionData,
  TransactionFilters,
  TransactionListComponentData,
  TransactionQueryParams,
  TransactionStatusChips,
} from "../transaction.model";
import { QueryParamsService } from "projects/common/src/lib/query/query-params.service";

@Component({
  selector: "ifb-transaction-list",
  templateUrl: "./transaction-list.component.html",
  styleUrls: ["./transaction-list.component.scss"],
  animations: [
    routerTransition,
    trigger("detailExpand", [
      state(
        "collapsed, void",
        style({ height: "0px", minHeight: "0", display: "none" })
      ),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
      transition(
        "expanded <=> void",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class TransactionListComponent
  implements OnInit, OnDestroy, AfterContentInit {
  private subs: Subscription[] = [];
  private _failureReasons: Map<string, string> = null;
  private _unsubscribeAll: Subject<any>;

  filters: TransactionFilters;
  activeFilters: FilterParameter[] = [];
  innerTab: boolean;
  canEditProcessedTransaction = false;
  adminEdit = false;
  data: TransactionListComponentData;
  readWriteTransaction: boolean;
  CanUserEditTransactionInStatusGrantedPermissions: UserPermissionItem[] = [];
  CanUserMoveTransactionToStatusGrantedPermissions: UserPermissionItem[] = [];
  canReadAccounts = false;
  canReadTransactions = false;
  animationMode: string;
  expanded: any = {};
  expandedElement: any;
  openedRow: boolean;
  saleforceId: string;
  canMakeDraw: boolean;
  canMakePayment: boolean;
  isOwnerAssigned: boolean;
  loanStatus = LoanStatus;
  selectedTabIndex = 0;
  promotionsToSelectMap: Map<string, string>;
  backToListUrl: string;

  constructor(
    private route: ActivatedRoute,
    private transactionService: TransactionService,
    private dialog: MatDialog,
    private accountService: AccountService,
    private userPermissionService: UserPermissionService,
    private _transactionFilterService: TransactionFilterService,
    private router: Router,
    private queryParamsService: QueryParamsService
  ) {
    this._unsubscribeAll = new Subject();
  }

  @ViewChild("tabGroup", { static: true }) tabGroup: MatTabGroup;

  get TransactionStatus() {
    return TransactionStatus;
  }

  get TransactionType() {
    return TransactionType;
  }

  get status() {
    return TransactionStatusChips;
  }

  get TransactionTypeNames() {
    return TransactionTypeNames;
  }

  get AccountStatus() {
    return LoanStatus;
  }

  ngOnInit() {
    this.subs = [
      this.route.data.subscribe((it: TransactionListComponentData) => {
        this.data = it;
        this._failureReasons = new Map(
          it.failureReasons.map((r): [string, string] => [r.code, r.name])
        );
        this.promotionsToSelectMap = new Map(
          it.promotionsToSelect?.map((r): [string, string] => [
            r.id.toString(),
            r.name,
          ])
        );
      }),
      this.route.queryParams.subscribe((params) => {
        this.queryParams = new TransactionQueryParams(this.queryParamsService.init((params)));
        this.activeFilters = this._transactionFilterService.addFilterParameters(
          this.queryParams,
          this.promotionsToSelectMap
        );
        this.backToListUrl = this.router.url;
      }),
      this.route.params.subscribe(
        (params) => (this.saleforceId = params.uniqueId)
      ),
    ];

    this.filters = new TransactionFilters(this.queryParams);
    this.innerTab =
      this.route.snapshot.data.appSidenavItem === "account" ||
      this.data.account != null;
    this.animationMode = this.innerTab ? "fly" : "fade";
    this.isOwnerAssigned =
      this.data.account && this.data.accountInfo
        ? this.data.account.owners && this.data.account.owners.length > 0 : true;

    this.dataSet();

    this.paginator.pageSize = this.queryParams.limit;
    this.paginator.pageIndex = this.queryParams.skip / this.queryParams.limit;

    this.userPermissionService
      .granted([readOnly("servicing-transactions")])
      .subscribe((res) => (this.canReadTransactions = res));

    this.userPermissionService
      .granted([readWrite("servicing-transactions")])
      .subscribe((res) => (this.readWriteTransaction = res));

    this.userPermissionService
      .granted([readWrite("servicing-editing-processed-transactions")])
      .subscribe((res) => (this.canEditProcessedTransaction = res));

    for (
      let index = 0;
      index < CanUserEditTransactionInStatus.length;
      index++
    ) {
      this.CanUserEditTransactionInStatusGrantedPermissions[index] = {
        permission: [CanUserEditTransactionInStatus[index]],
        hidden: true,
      };
    }
    this.userPermissionService
      .visibilityList(this.CanUserEditTransactionInStatusGrantedPermissions)
      .subscribe((res) => res);

    for (
      let index = 0;
      index < CanUserMoveTransactionToStatus.length;
      index++
    ) {
      this.CanUserMoveTransactionToStatusGrantedPermissions[index] = {
        permission: [CanUserMoveTransactionToStatus[index]],
        hidden: true,
      };
    }
    this.userPermissionService
      .visibilityList(this.CanUserMoveTransactionToStatusGrantedPermissions)
      .subscribe((res) => res);

    this.userPermissionService
      .granted([readWrite("servicing-subtransactions")])
      .subscribe((res) => (this.adminEdit = res));

    this.userPermissionService
      .granted([readOnly("servicing-accounts")])
      .subscribe((res) => (this.canReadAccounts = res));
  }

  ngAfterContentInit(): void {
    this.accountService.obsCurrentLoan
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        if (res) this.data.account = res;
      });
  }

  ngOnDestroy() {
    this.subs.forEach((it) => it.unsubscribe());
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this.accountService.onCurrentLoanChanged.next(null);
  }

  dataSet() {
    this.canMakeDraw =
      !this.data.account && !this.data.accountInfo
        ? true
        : this.data.account.allowDraw && !this.data.liquidityEvent?.resolutionStatus;
    this.canMakePayment =
      !this.data.account && !this.data.accountInfo
        ? true
        : this.data.account.allowPayment;
  }

  activeFilterRemoved(activefilters: FilterParameter[]): void {
    if (!activefilters.length) return;
    activefilters.forEach((item) => {
      this.filters.remove(item.id);
      this._transactionFilterService.filter(
        this.filters,
        this.data,
        this.saleforceId,
        this.paginator,
        this.sort
      );
    });
  }

  downloadHistory() {
    const loanId = this.data?.account?.id;
    const query = loanId ? { ...this.queryParams, loanId } : this.queryParams;
    this.transactionService.download(query);
  }

  downloadUpcoming() {
    this.accountService.getAmortizationCsv(this.data.account.id);
  }

  downloadFull() {
    this.transactionService.downloadHistoryUpcoming(
      this.data.account.id
    );
  }

  showType(type: number): string {
    return TransactionTypeLabel.get(type);
  }

  showReasons(values: string[]) {
    if (!values || values.length === 0 || !this._failureReasons) return;

    return values.map((v) => {
      const name = this._failureReasons.get(v);
      return name ? name : v;
    });
  }

  getBankVerificationAvatarIcon(trx: TransactionData) {
    if (trx.underwritingDate) {
      if (trx.wasScreenShareRequiredAtUWTime) return "screen_share";
      else if (trx.wasAbleToUnderwriteAtUWTime) return "find_in_page";
      else if (!trx.wasAbleToUnderwriteAtUWTime) return "search_off";
    } else {
      if (trx.isScreenShareRequired) return "screen_share";
      else if (trx.isAbleToUnderwrite) return "find_in_page";
      else if (!trx.isAbleToUnderwrite) return "search_off";
    }
  }

  getBankVerificationAvatarClass(trx: TransactionData) {
    if (!trx.underwritingDate) {
      if (trx.isScreenShareRequired) return "avatar medium mat-orange-bg";
      else if (trx.isAbleToUnderwrite) return "avatar medium mat-indigo-bg";
      else if (!trx.isAbleToUnderwrite) return "avatar medium mat-red-bg";
    } else {
      return "avatar medium mat-light-grey-bg";
    }
  }

  getBankVerificationTooltip(trx: TransactionData) {
    if (trx.underwritingDate) {
      if (trx.wasScreenShareRequiredAtUWTime)
        return "Screen share was required at UW time";
      else if (trx.wasAbleToUnderwriteAtUWTime)
        return "Entity was able to underwrite at UW time";
      else if (!trx.wasAbleToUnderwriteAtUWTime)
        return "Entity was not able to underwrite at UW time";
    } else {
      if (trx.isScreenShareRequired) return "Screen share required";
      else if (trx.isAbleToUnderwrite) return "Entity is able to underwrite";
      else if (!trx.isAbleToUnderwrite)
        return "Entity is not able to underwrite";
    }
  }

  getBankLinkingStatusAvatarIcon(trx: TransactionData) {
    if (trx.underwritingDate) {
      if (trx.wasTransferBankAccountLinkedAtUWTime) return "link";
      else if (trx.wasTransferBankAccountLinkedAtUWTime === false)
        return "link_off";
    } else {
      if (trx.isTransferBankAccountLinked) return "link";
      else if (!trx.isTransferBankAccountLinked) return "link_off";
    }
  }

  getBankLinkingStatusAvatarClass(trx: TransactionData) {
    if (!trx.underwritingDate) {
      if (trx.isTransferBankAccountLinked) return "avatar medium mat-green-bg";
      else if (!trx.isTransferBankAccountLinked)
        return "avatar medium mat-red-bg";
    } else if (
      trx.wasTransferBankAccountLinkedAtUWTime ||
      trx.wasTransferBankAccountLinkedAtUWTime === false
    ) {
      return "avatar medium mat-light-grey-bg";
    }
  }

  getBankLinkingStatusTooltip(trx: TransactionData) {
    if (trx.underwritingDate) {
      return trx.wasTransferBankAccountLinkedAtUWTime ?
        "Bank account was linked at UW time" : "Bank account was unlinked at UW time";
    } else {
      return trx.isTransferBankAccountLinked ?
        "Bank account linked" : "Bank account unlinked";
    }
  }

  getConditionsTooltip(trx: TransactionData): string {
    let tooltipText = [];

    trx?.drawRequirements?.forEach(requirement => {
      switch (requirement.type) {
        case DrawRequirementType.WelcomeCallCompleted:
          tooltipText.push(!requirement.requirementCompleted ?
            "Welcome call not completed yet" :
            `Welcome call completed on ${new Date(
              requirement.requirementCompletedOnUtc
            ).toLocaleDateString()}`);
          break;

        case DrawRequirementType.TwoUniquePhoneNumbers:
          tooltipText.push(!requirement.requirementCompleted ?
            "Unique Phone Numbers not met" :
            "Unique Phone Numbers met");
          break;

        case DrawRequirementType.UCCFilingCompleted:
          tooltipText.push(!requirement.requirementCompleted ?
            "UCC Filing not met" :
            `UCC Filing met on ${new Date(
              requirement.requirementCompletedOnUtc
            ).toLocaleDateString()}`);
          break;

        case DrawRequirementType.RequestorLocationMatchesBusinessAddress:
          const requestorState = trx.customerDrawGeographicalPosition?.region;
          tooltipText.push(!requirement.requirementCompleted ?
            `Requestor’s location ${!!requestorState ? `(${requestorState})` : ""} differs from business address (${trx.businessAddress?.state})` :
            "Requestor's location (state) matches business address");
          break;
      }
    });

    return tooltipText.join('\n');
  }

  getConditionsAvatarIcon(trx: TransactionData): string {
    const drawConditions = trx?.drawRequirements?.some(drawRequirement => drawRequirement.required && !drawRequirement.requirementCompleted)

    return drawConditions ? "dangerous" : "fact_check";
  }

  getConditionsAvatarClass(trx: TransactionData): string {
    let color: string;
    const drawConditions = trx?.drawRequirements?.some(drawRequirement => drawRequirement.required && !drawRequirement.requirementCompleted)

    if (trx.status === TransactionStatus.Pending && trx?.type === TransactionType.Draw) {
      color = drawConditions ? "mat-red-bg" : "mat-green-bg";
    } else {
      color = "mat-light-grey-bg";
    }

    return `avatar medium ${color}`;
  }

  getPromotionsString(trx: TransactionData): string {
    return trx.promotions?.map((p) => p.name).join(", ");
  }

  filterDialog() {
    TransactionListFilterDialogComponent.show(
      this.dialog,
      this.queryParams,
      this.data,
      this.canReadAccounts
    ).subscribe((result) => {
      if (result) {
        this.filters = result;
        this.paginator.firstPage();
        this._transactionFilterService.filter(
          result,
          this.data,
          this.saleforceId,
          this.paginator,
          this.sort
        );
      }
    });
  }

  sortData(sort: Sort) {
    this._transactionFilterService.filter(
      this.filters,
      this.data,
      this.saleforceId,
      this.paginator,
      this.sort
    );
  }

  setPage(event: PageEvent) {
    this._transactionFilterService.filter(
      this.filters,
      this.data,
      this.saleforceId,
      this.paginator,
      this.sort
    );
  }

  fetchData(event) {
    this.dataSet();

    const uniqueId = this.route.snapshot.params.uniqueId;

    if (uniqueId)
      this.accountService
        .getByUniqueId(uniqueId)
        .subscribe((res: AccountData) => {
          if (res) this.refreshData(parseNumber(res.id));
        });
    else this.refreshData(parseNumber(this.route.snapshot.params.id));
  }

  refreshData(loanId: number) {
    const query = !loanId
      ? this.queryParams
      : this.data?.account?.renewalFor
        ? { ...this.queryParams, loanId, renewalFor: this.data.account.renewalFor }
        : { ...this.queryParams, loanId };

    this.transactionService
      .query(query)
      .subscribe((res) => (this.data.transactionQueryResult = res));
  }

  @ViewChild("paginator", { static: true })
  paginator: MatPaginator;

  @ViewChild("paginator2", { static: true })
  paginator2: MatPaginator;

  get accountPaymentListPaginator() {
    if (this.innerTab) return this.paginator2;
    else return null;
  }

  queryParams: TransactionQueryParams;

  @ViewChild(MatSort)
  sort: MatSort;

  getDisplayedColumns(): string[] {
    return this.tableColumns
      .filter((cd) => (this.innerTab ? cd.showInnerTab : cd.showMainList))
      .map((cd) => cd.def);
  }

  tableColumns = [
    { def: "type-icon", showInnerTab: true, showMainList: true },
    { def: "bankLinkingStatus", showInnerTab: true, showMainList: true },
    { def: "welcomeCallStatus", showInnerTab: true, showMainList: true },
    { def: "transactionHistory", showInnerTab: true, showMainList: true },
    { def: "renewalFor", showInnerTab: true, showMainList: false },
    { def: "date", showInnerTab: true, showMainList: true },
    { def: "type", showInnerTab: true, showMainList: true },
    { def: "amount", showInnerTab: true, showMainList: true },
    { def: "promotion", showInnerTab: true, showMainList: true },
    { def: "status", showInnerTab: true, showMainList: true },
    { def: "businessName", showInnerTab: false, showMainList: true },
    { def: "loanPrincipalBalance", showInnerTab: true, showMainList: true },
    { def: "loanCreditLimit", showInnerTab: true, showMainList: true },
    { def: "loanNumber", showInnerTab: true, showMainList: true },
    { def: "rejectReasons", showInnerTab: true, showMainList: true },
    { def: "processDate", showInnerTab: true, showMainList: true },
    { def: "disbursedAmount", showInnerTab: true, showMainList: true },
    { def: "thirdPartyPayoffAmount", showInnerTab: true, showMainList: true },
    { def: "deferredFeeAmount", showInnerTab: true, showMainList: true },
    { def: "cashFeeAmount", showInnerTab: true, showMainList: true },
    { def: "principalAmount", showInnerTab: true, showMainList: true },
    { def: "interestAmount", showInnerTab: true, showMainList: true },
    { def: "closingBalance", showInnerTab: true, showMainList: false },
    { def: "outstandingInterest", showInnerTab: true, showMainList: false },
    { def: "outstandingPrincipal", showInnerTab: true, showMainList: false },
    { def: "creditLimit", showInnerTab: true, showMainList: false },
    { def: "utilization", showInnerTab: true, showMainList: false },
    { def: "totalDeferredFees", showInnerTab: true, showMainList: false },
    { def: "totalCashFees", showInnerTab: true, showMainList: false },
    { def: "totalFees", showInnerTab: true, showMainList: false },
    { def: "totalInterest", showInnerTab: true, showMainList: false },
    { def: "totalPrincipal", showInnerTab: true, showMainList: false },
    { def: "totalPayback", showInnerTab: true, showMainList: false },
    { def: "initiator", showInnerTab: true, showMainList: true },
    { def: "creator", showInnerTab: true, showMainList: true },
    { def: "modifier", showInnerTab: true, showMainList: true },
    { def: "advisor", showInnerTab: false, showMainList: true },
    { def: "maintained", showInnerTab: true, showMainList: true },
    { def: "id", showInnerTab: true, showMainList: true },
    { def: "edit", showInnerTab: true, showMainList: true },
  ];

  addTransaction(type: TransactionTypeNames) {
    if (this.data.account && this.data.accountInfo) {
      this.router.navigate([
        `/transaction-new/${type}/${this.data.account.id}`,
      ]);
    } else {
      this.router.navigate([`/transaction-new/${type}`]);
    }
  }

  addAdminTransaction() {
    if (this.data.account && this.data.accountInfo) {
      this.router.navigate([`/transaction-new-admin/${this.data.account.id}`]);
    } else {
      this.router.navigate([`/transaction-new-admin/`]);
    }
  }

  transactionDetailsLink(tx: TransactionData) {
    if (!this.canReadTransactions || !this.canReadAccounts) {
      return;
    }

    this.router.navigate([`account/${tx.accountId}/transaction/${tx.id}/view-mode`], { queryParams: { backTo: this.backToListUrl } });
  }

  accountDetailsLink(tx: TransactionData) {
    if (!this.canReadAccounts) return;
    return `/account/${tx.accountId}/profile`;
  }

  showDetails(element: any): void {
    this.expandedElement = this.expandedElement !== element ? element : null;
    this.openedRow = this.expandedElement != null;
  }

  getAvatarIcon(type: number) {
    switch (type) {
      case TransactionType.Draw:
        return "D";
      case TransactionType.Payment:
        return "P";
      case TransactionType.BalanceTransfer:
        return "B";
      case TransactionType.Fee:
        return "F";
      case TransactionType.Premium:
        return "$";
    }
  }
}
